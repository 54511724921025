import Cookies from 'js-cookie'
var CryptoJS = require('crypto-js')

export const FormatNumber = (num)=>{
    
    let newNumber = Number(num)
    return  newNumber.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}


export const APP_KEY =process.env.REACT_APP_APP_KEY;


export const studentName = Cookies.get('gmmiastkstnm')?CryptoJS.AES.decrypt(Cookies.get('gmmiastkstnm'), APP_KEY).toString(CryptoJS.enc.Utf8):'null'
export const classID = Cookies.get('gmmiasclkysd')?CryptoJS.AES.decrypt(Cookies.get('gmmiasclkysd'), APP_KEY).toString(CryptoJS.enc.Utf8):'null'
export const sectionID = Cookies.get('gmmiassckayd')?CryptoJS.AES.decrypt(Cookies.get('gmmiassckayd'), APP_KEY).toString(CryptoJS.enc.Utf8):'null'

export const familyCode = Cookies.get('gmmiafmcdk')?CryptoJS.AES.decrypt(Cookies.get('gmmiafmcdk'), APP_KEY).toString(CryptoJS.enc.Utf8):'null'
export const studentCode= Cookies.get('gmmiastkauscd')?CryptoJS.AES.decrypt(Cookies.get('gmmiastkauscd'), APP_KEY).toString(CryptoJS.enc.Utf8):'null'
export const Token = Cookies.get('gmmiastkamtk')?CryptoJS.AES.decrypt(Cookies.get('gmmiastkamtk'), APP_KEY).toString(CryptoJS.enc.Utf8):'null'
export const config = {
    headers: { 'content-type': 'multipart/form-data'}
} 
export const Currency = '₦'



export  const mainUrl = process.env.REACT_APP_MAIN_URL
export  const ServerUrl = "https://app."+mainUrl+'/controller'
export  const ImagesUrl = "https://app."+mainUrl+'/images'
export  const StudentUrl= "https://learners."+mainUrl
export  const LocalUrl = process.env.REACT_APP_LOCAL_URL
export const schoolName = process.env.REACT_APP_SCHOOL_NAME


export  const teacherUrl= "https://staff."+mainUrl
export  const ParentUrl=  "https://parent."+mainUrl
export  const applicantUrl=  "https://form."+mainUrl