
import {Helmet} from "react-helmet";
import { ImagesUrl } from "./include";

const Seo =()=> {
  return (
    <Helmet>

<title>LEARNERS:. GREAT MINDS MODEL INTELLECTUAL ACADEMY </title>
  
    <meta name="description" content="Welcome to GREAT MINDS MODEL INTELLECTUAL ACADEMY, Abuja.The one of the leading and most sought after Nursery/Primary and Secondary School in Abuja. it was incorporated in DEI DEI, Nigeria with Registration Number 2353946...." />
    
<meta  name="keywords" content="SMS, Portal, Student Result, Staff Login, Admin Login, GREAT MINDS MODEL INTELLECTUAL ACADEMY, abuja, schools in abuja, jahi schools in nigeria, great mind" />

    <link rel="shortcut icon" href={ImagesUrl+'/about/logo.png'} type="image/x-icon" />
    
    <meta name="author" content="Admotek Solutions-08032950881" />

    <meta property="og:title" content="LEARNERS:. GREAT MINDS MODEL INTELLECTUAL ACADEMY"/> 
    <meta property="og:site_name" content="GMMIA"/> 
    <meta property="og:description" content="Welcome to GREAT MINDS MODEL INTELLECTUAL ACADEMY, Abuja.The one of the leading and most sought after Nursery/Primary and Secondary School in Abuja. it was incorporated in DEI DEI, Nigeria with Registration Number 2353946..."/> 
    <meta property="og:image" content="https://app.greatmindsmia.com/images/background/main.png"/>  
    <meta property="og:url" content="https://app.greatmindsmia.com"/> 

    <meta property="og:image:width" content="1200" />
    <meta property="og:image:height" content="630" />

    <meta name="twitter:title" content="LEARNERS:. GREAT MINDS MODEL INTELLECTUAL ACADEMY"/> 
    <meta name="twitter:card" content="GMMIA"/> 
    <meta name="twitter:description" content="Welcome to GREAT MINDS MODEL INTELLECTUAL ACADEMY, Abuja.The one of the leading and most sought after Nursery/Primary and Secondary School in Abuja. it was incorporated in DEI DEI, Nigeria with Registration Number 2353946..."/> 
    <meta property="og:image" content="https://app.greatmindsmia.com/images/background/main.png"/>  
    <meta name="twitter:url" content="https://app.greatmindsmia.com"/> 
    <meta property="og:type" content="website"/> 
    <meta property="og:publisher" content="Admotek Solutions-08032950881"/>

    </Helmet>
  )
}



export default Seo